export function classList(...classes: (string | false | null | undefined)[]) {
  return classes.filter(c => !!c).join(' ');
}

export async function sleep(ms: number) {
  return new Promise(r => setTimeout(r, ms))
}

export type falsy = false | 0 | "" | null | undefined

export function truthy<T>(any: T | falsy): any is T {
  return !!any
}
